import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import { ADD_TO_CONVERSATION } from "../../graphql/conversations.graphql";
import {
  Event as EventType,
  EventVariables,
} from "../../graphql/__generated__/Event";
import {
  AddToConversation,
  AddToConversationVariables,
} from "../../graphql/__generated__/AddToConversation";
import { EVENT } from "../../graphql/events.graphql";
import Conversation from "../conversations/Conversation";

type Params = {
  id: string;
};
export default function GatedEntry() {
  let { id } = useParams<Params>();
  const { data: collection, loading: collectionLoading } = useQuery<
    EventType,
    EventVariables
  >(EVENT, {
    variables: {
      id: id,
    },
  });
  const [
    addToConversation,
    { loading: isAddingToConversation, error: errorAddingToConversation },
  ] = useMutation<AddToConversation, AddToConversationVariables>(
    ADD_TO_CONVERSATION
  );
  const wallet = useAnchorWallet();
  const [convoId, setConvoId] = useState<string | undefined>();

  const checkWallet = async (): Promise<string[]> => {
    if (wallet) {
      const convo = await addToConversation({
        variables: { id: id, publicKey: wallet.publicKey.toBase58() },
      });
      if (convo.data) {
        setConvoId(convo.data.addToConversation?.id);
      }
    }
    return [];
  };

  return (
    <>
      {!wallet ? (
        <WalletModalProvider>
          <WalletMultiButton />
          <WalletDisconnectButton />
        </WalletModalProvider>
      ) : (
        <button onClick={checkWallet}>Try me</button>
      )}
      {convoId ? <Conversation id={convoId} /> : null}
    </>
  );
}
