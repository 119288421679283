import { gql } from "@apollo/client";

export const EVENTS = gql`
  query Events {
    events {
      id
      name
      venue {
        id
        name
        description
        loc {
          lat
          lng
        }
      }
      type
      status
      description
      url
      sale_end
      sale_start
      event_end
      event_start
      tags {
        name
      }
    }
  }
`;

export const EVENT = gql`
  query Event($id: String!) {
    event(id: $id) {
      id
      name
      venue {
        id
        name
        description
        loc {
          lat
          lng
        }
      }
      type
      status
      description
      url
      sale_end
      sale_start
      event_end
      event_start
      tags {
        name
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent(
    $name: String!
    $description: String
    $url: String
    $type: Int!
    $event_start: DateTime!
    $event_end: DateTime!
    $sale_start: DateTime!
    $sale_end: DateTime!
  ) {
    createEvent(
      name: $name
      description: $description
      url: $url
      type: $type
      event_start: $event_start
      event_end: $event_end
      sale_start: $sale_start
      sale_end: $sale_end
    ) {
      id
      name
      venue {
        id
        name
        description
        loc {
          lat
          lng
        }
      }
      type
      status
      description
      url
      sale_end
      sale_start
      event_end
      event_start
      tags {
        name
      }
    }
  }
`;

export const LINK_COLLECTION = gql`
  mutation LinkCollection($eventId: String!, $collectionId: String!) {
    linkCollection(eventId: $eventId, collectionId: $collectionId)
  }
`;
