import { gql } from "@apollo/client";

export const COLLECTIONS = gql`
  query Collections {
    collections {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
        status
      }
      mint_start_time
      price_lamports
      template_s3_url
    }
  }
`;

export const COLLECTION = gql`
  query Collection($id: String!) {
    collection(id: $id) {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
        status
      }
      mint_start_time
      price_lamports
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation CreateCollection(
    $name: String!
    $maxCount: Int!
    $symbol: String!
    $royalty: Int!
    $templateImage: Upload!
    $mintDate: DateTime!
    $price: Float!
  ) {
    createCollection(
      name: $name
      maxCount: $maxCount
      symbol: $symbol
      royalty: $royalty
      templateImage: $templateImage
      price: $price
      mintDate: $mintDate
    ) {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
      }
    }
  }
`;

export const GENERATE_ITEMS_FROM_TEMPLATE = gql`
  mutation GenerateItemsFromTemplate($id: String!) {
    generateItemsFromTemplate(id: $id)
  }
`;

export const ADD_ITEMS_TO_SOLANA = gql`
  mutation AddItemsToSolana($id: String!) {
    addItemsToSolana(id: $id) {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
        status
      }
    }
  }
`;

export const CREATE_CANDY_MACHINE = gql`
  mutation CreateCandyMachine($id: String!) {
    createCandyMachine(id: $id) {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
        status
      }
      mint_start_time
      price_lamports
    }
  }
`;

export const UPDATE_CANDY_MACHINE = gql`
  mutation UpdateCandyMachine(
    $id: String!
    $mintDate: DateTime!
    $priceSols: Float!
  ) {
    updateCandyMachine(id: $id, mint_date: $mintDate, price_sols: $priceSols) {
      id
      metadata
      program_account_address
      candy_machine_address
      status
      items {
        id
        ipfs_metadata
        metadata
        s3_url
      }
      mint_start_time
      price_lamports
    }
  }
`;
