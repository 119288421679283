import React from "react";
import { Item_item as ItemType } from "../../graphql/__generated__/Item";

export default function Item(item: ItemType, addToCloudStorage: Function) {
  return (
    <>
      <div>
        <h1>Item {item.id}</h1>

        <h2>Metadata</h2>
        <p>{JSON.stringify(item.metadata)}</p>

        <h2>IPFS Metadata</h2>
        <p>{JSON.stringify(item.ipfs_metadata)}</p>

        <h2>S3 Asset URL</h2>
        <p>{item.s3_url}</p>

        <h2>Status</h2>
        <p>{item.status}</p>

        <button
          onClick={() => addToCloudStorage({ variables: { id: item.id } })}
        >
          Add To IPFS
        </button>
      </div>
    </>
  );
}
