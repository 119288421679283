import { gql } from "@apollo/client";

export const CONVERSATION = gql`
  query Conversation($id: String!) {
    conversation(id: $id) {
      id
      status
      event {
        id
        name
        url
        description
        type
        status
        event_start
        event_end
        tags {
          name
        }
        collections {
          id
        }
      }
    }
  }
`;

export const MESSAGES = gql`
  query Messages($id: String!, $limit: Int, $offset: Int) {
    messages(id: $id, limit: $limit, offset: $offset) {
      id
      body
      type
      user {
        profile {
          fullname
        }
      }
      created_at
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($id: String!, $body: String!) {
    sendMessage(id: $id, body: $body) {
      id
      body
      type
      user {
        profile {
          fullname
        }
      }
      created_at
    }
  }
`;

export const ADD_TO_CONVERSATION = gql`
  mutation AddToConversation($id: String!, $publicKey: String!) {
    addToConversation(id: $id, publicKey: $publicKey) {
      id
      status
      event {
        id
        name
        url
        description
        type
        status
        event_start
        event_end
        tags {
          name
        }
        collections {
          id
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_CONVERSATION = gql`
  subscription SubscribeToConversation($id: String!) {
    subscribeToConversation(id: $id) {
      id
      body
      type
      user {
        profile {
          fullname
        }
      }
      created_at
    }
  }
`;
