import React from "react";
import { useQuery } from "@apollo/client";
import { EVENTS } from "../../graphql/events.graphql";
import { Events as EventsType } from "../../graphql/__generated__/Events";
import EventListItem from "./EventListItem";

export default function EventList() {
  const { data, loading, error } = useQuery<EventsType>(EVENTS);

  const events = data?.events;
  return (
    <>
      <div className="">
        {events?.map((event) => {
          return <EventListItem {...event} />;
        })}
      </div>
    </>
  );
}
