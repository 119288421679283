import React, { useState } from "react";
import { Messages as MessagesType } from "../../graphql/__generated__/Messages";

export default function Messages(props: { data: MessagesType }) {
  return (
    <>
      {props.data.messages.map((msg) => {
        return (
          <li>{`${msg.user.profile?.fullname || "Anonymous"}: ${msg.body}`}</li>
        );
      })}
    </>
  );
}
