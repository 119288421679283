import React, { useEffect, useState } from "react";
import { cx, css } from "@emotion/css";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { currentUser, getOrCreatePhantomUser } from "../../utils/users_api";

// This should match the server auth.ts key
const PUBLIC_ENCRYPTED_MSG = "Open sesame!";

function WalletButton() {
  const [user, setUser] = useState<any>();
  const { publicKey, signMessage } = useWallet();

  const tryConnectingWallet = async () => {
    if (user) {
      return;
    }
    setUser(await currentUser());
    if (user) {
      return;
    }
    if (publicKey) {
      try {
        const signature = await trySendingMessage();
        if (signature) {
          await getOrCreatePhantomUser(
            publicKey.toBase58(),
            Buffer.from(signature)
          );
        }
        setUser(await currentUser());
      } catch (err) {
        console.log("err", err);
      }
    } else {
      console.log("not logged in");
    }
  };

  useEffect(() => {
    tryConnectingWallet();
  }, [publicKey]);

  const trySendingMessage = async () => {
    const encodedMessage = new TextEncoder().encode(PUBLIC_ENCRYPTED_MSG);
    // @ts-ignore
    const signedMessage = await signMessage(encodedMessage);
    return signedMessage;
  };

  return (
    <>
      {!user || !publicKey ? (
        <WalletModalProvider>
          <WalletMultiButton className={cx()}>
            Log in with Wallet
          </WalletMultiButton>
        </WalletModalProvider>
      ) : (
        <WalletDisconnectButton className="" />
      )}
    </>
  );
}

export default WalletButton;
