import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { EVENT } from "../../graphql/events.graphql";
import { Event as EventType } from "../../graphql/__generated__/Event";
import {
  GetPaymentIntent,
  GetPaymentIntentVariables,
} from "../../graphql/__generated__/GetPaymentIntent";
import { GET_PAYMENT_INTENT } from "../../graphql/payment.graphql";
import Stripe from "../payment/Stripe";
import { cx } from "@emotion/css";
import { useParams } from "react-router-dom";
import CreateCollection from "../collections/CreateCollection";

const stripePromise = loadStripe("pk_test_ED7SmF8zYFm9sUNTD27PRk7Y");

export default function EventPage() {
  let { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<EventType>(EVENT, {
    variables: {
      id: id,
    },
  });

  const event = data?.event;
  const [
    getPaymentIntent,
    { loading: isLoadingPaymentIntent, error: errorGettingPaymentIntent },
  ] = useMutation<GetPaymentIntent, GetPaymentIntentVariables>(
    GET_PAYMENT_INTENT
  );

  const [paymentIntentSecret, setPaymentIntentSecret] = useState<
    string | undefined
  >();

  const onPayWithStripe = async () => {
    if (event) {
      const paymentIntent = await getPaymentIntent({
        variables: {
          collectionId: "9f7f0fe4-b1ac-499c-a378-b742ef80a016",
          amountCents: 1000,
        },
      });
      setPaymentIntentSecret(paymentIntent.data?.getPaymentIntent);
    }
  };

  return (
    <>
      <div className="">
        {paymentIntentSecret ? (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: paymentIntentSecret }}
          >
            <Stripe
              paymentIntentSecret={paymentIntentSecret}
              redirectUrl={window.location.toString()}
            />
          </Elements>
        ) : (
          <span className={cx("button is-success")} onClick={onPayWithStripe}>
            Pay With Stripe
          </span>
        )}
      </div>
      <div className="">
        <CreateCollection eventId={id} />
      </div>
    </>
  );
}
