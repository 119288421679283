import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import TextareaAutosize from "react-textarea-autosize";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { Crop } from "react-image-crop";

import { MAX_IMAGE_TEMPLATE_SIZE } from "../../utils/constants";
import { CREATE_COLLECTION } from "../../graphql/collections.graphql";
import {
  CreateCollection,
  CreateCollectionVariables,
} from "../../graphql/__generated__/CreateCollection";
import {
  LinkCollection,
  LinkCollectionVariables,
} from "../../graphql/__generated__/LinkCollection";
import { useHistory } from "react-router-dom";
import { LINK_COLLECTION } from "../../graphql/events.graphql";
import { getCroppedImg } from "../../utils/images";

export default function CreateCollectionForm(props: { eventId: string }) {
  const [name, setName] = useState<string>("");
  const [symbol, setSymbol] = useState<string>("dad");
  const [royalty, setRoyalty] = useState<number>(0);
  const [price, setPrice] = useState<number | null>(null);
  const [mintStartTime, setMintStartTime] = useState<Date | null>(null);
  const [maxTickets, setMaxTickets] = useState<number>(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const imageFileInput = useRef<HTMLInputElement>(null);
  const [crop, setCrop] = useState<Crop>({
    aspect: 4 / 4,
    width: 256,
    height: 256,
    x: 0,
    y: 0,
    unit: "px",
  });
  let history = useHistory();

  const [
    createCollection,
    { loading: isCreatingCollection, error: errorCreatingCollection },
  ] = useMutation<CreateCollection, CreateCollectionVariables>(
    CREATE_COLLECTION
  );
  const [
    LinkCollection,
    { loading: isLinkingCollection, error: errorLinkingCollection },
  ] = useMutation<LinkCollection, LinkCollectionVariables>(LINK_COLLECTION);
  return (
    <div className="">
      <div>Create a Collection</div>
      <label>Name</label>
      <input
        type="text"
        placeholder="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label>Symbol</label>
      <input
        type="text"
        placeholder="symbol"
        maxLength={6}
        pattern="[A-Za-z]{6}"
        value={symbol}
        onChange={(e) => setSymbol(e.target.value)}
      />
      <label>Royalty (%)</label>
      <input
        type="range"
        min={0}
        max={100}
        step={1}
        value={royalty}
        onChange={(e) => setRoyalty(e.target.valueAsNumber)}
      />
      <output>{royalty}</output>
      <label>Max Tickets</label>
      <input
        name="max_tickets"
        type="number"
        placeholder="100"
        value={maxTickets}
        onChange={(e) => setMaxTickets(e.target.valueAsNumber)}
      />

      <label>Image Ticket</label>
      <input
        type="file"
        ref={imageFileInput}
        id="template"
        name="template"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          var file = e.target.files && e.target.files[0];
          if (file == null || !e.target.validity.valid) {
            // TODO: handle error properly
            throw new Error("There was an error with the file selected");
          }

          //Initiate the JavaScript Image object.
          const image = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = window.URL.createObjectURL(file);

          //Validate the File Height and Width.
          image.onload = function () {
            var height = image.height;
            var width = image.width;
            if (
              height > MAX_IMAGE_TEMPLATE_SIZE ||
              width > MAX_IMAGE_TEMPLATE_SIZE
            ) {
              alert(
                `Height and Width must not exceed ${MAX_IMAGE_TEMPLATE_SIZE}px.`
              );
              if (imageFileInput.current != null) {
                imageFileInput.current.value = "";
              }
              return false;
            }
            setImage(image);
            setImageFile(file);
            return true;
          };
        }}
      />
      {image ? (
        <ReactCrop
          src={image.src}
          crop={crop}
          onChange={(crop) => {
            crop.aspect = 4 / 4;
            setCrop(crop);
          }}
          onComplete={async (crop) => {
            const blob = await getCroppedImg(image, crop, 256, 256);
            if (blob && imageFile) {
              setImageFile(new File([blob], imageFile.name));
            }
          }}
        />
      ) : null}
      <label>Mint Start Time</label>
      <input
        type="text"
        name="mintStartTime"
        onChange={(e) => {
          const d = Date.parse(e.target.value);
          if (!isNaN(d)) {
            setMintStartTime(new Date(d));
          }
        }}
      />
      <label>Price (in SOL)</label>
      <input
        type="number"
        name="price"
        step="0.005"
        onChange={(e) => {
          const n = Number(e.target.value);
          if (!isNaN(n)) {
            setPrice(n);
          }
        }}
      ></input>
      <button
        onClick={async () => {
          if (imageFile != null && symbol !== "" && price && mintStartTime) {
            const collectionResp = await createCollection({
              variables: {
                name: name,
                maxCount: maxTickets,
                symbol,
                royalty: royalty,
                templateImage: imageFile,
                price: price,
                mintDate: mintStartTime,
              },
            });
            if (collectionResp.data?.createCollection) {
              const collectionId = collectionResp.data.createCollection.id;
              const linkResp = await LinkCollection({
                variables: {
                  eventId: props.eventId,
                  collectionId: collectionId,
                },
              });
              if (linkResp.data?.linkCollection) {
                history.push(`/collection/${collectionId}`);
              }
            }
          } else {
            // TODO: handle errors
            throw new Error(
              `Invalid inputs imageFile: ${imageFile}, symbol: ${symbol}`
            );
          }
        }}
      >
        CreateCollection
      </button>
    </div>
  );
}
