import { Crop } from "react-image-crop";

export async function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop,
  desiredWidth: number,
  desiredHeight: number
): Promise<Blob | null> {
  const canvas = document.createElement("canvas");
  canvas.width = desiredWidth;
  canvas.height = desiredHeight;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return null;
  }
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    desiredWidth,
    desiredHeight
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob: Blob | null) => {
        resolve(blob);
      },
      "image/png",
      1
    );
  });
}
