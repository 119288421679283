import React, { useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getCloverWallet,
  getSolletWallet,
  getLedgerWallet,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

import WalletButton from "./components/login/WalletButton";
import Collection from "./components/collections/Collection";
import GatedEntry from "./components/collections/GatedEntry";
import Landing from "./components/Landing";
import TopNav from "./components/TopNav";
import EventPage from "./components/events/EventPage";
import EventList from "./components/events/EventList";
import CreateEventForm from "./components/events/CreateEventForm";
import QRCode from "./components/collections/QRCode";
import DiscordCallback from "./components/discord/DiscordCallback";
import DiscordUserCallback from "./components/discord/DiscordUserCallback";

function App() {
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  // Default styles that can be overridden by your app
  require("@solana/wallet-adapter-react-ui/styles.css");
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getCloverWallet(),
      getLedgerWallet(),
      getSlopeWallet(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <Router>
          <TopNav />
          <Switch>
            <Route path="/wallet_button">
              <WalletButton />
            </Route>
            <Route path="/events/create">
              <CreateEventForm />
            </Route>
            <Route path={`/event/:id`}>
              <EventPage />
            </Route>
            <Route path="/events">
              <EventList />
            </Route>
            <Route path={"/collection/:id"}>
              <Collection />
            </Route>
            <Route path={"/gated_entry/:id"}>
              <GatedEntry />
            </Route>
            <Route path={"/profile"}>
              <QRCode />
            </Route>
            <Route path={"/discord/callback"}>
              <DiscordCallback />
            </Route>{" "}
            <Route path={"/discord/user/auth"}>
              <DiscordUserCallback />
            </Route>
            <Route path={"/"}>
              <Landing />
            </Route>
          </Switch>
        </Router>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
