import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  ADD_ITEMS_TO_SOLANA,
  COLLECTION,
  CREATE_CANDY_MACHINE,
} from "../../graphql/collections.graphql";
import { useParams } from "react-router-dom";
import {
  Collection as CollectionType,
  CollectionVariables,
} from "../../graphql/__generated__/Collection";
import Item from "./Item";
import { ADD_TO_CLOUD_STORAGE } from "../../graphql/items.graphql";
import {
  AddToCloudStorage,
  AddToCloudStorageVariables,
} from "../../graphql/__generated__/AddToCloudStorage";
import {
  AddItemsToSolana,
  AddItemsToSolanaVariables,
} from "../../graphql/__generated__/AddItemsToSolana";
import {
  CreateCandyMachine,
  CreateCandyMachineVariables,
} from "../../graphql/__generated__/CreateCandyMachine";
import { awaitTransactionSignatureConfirmation } from "../../utils/connection";
import { mintOneToken } from "../../utils/mint";
import { SOLANA_PUBLIC_KEY } from "../../utils/constants";
import GenerateItems from "./GenerateItems";

type Params = {
  id: string;
};

export default function Collection() {
  let { id } = useParams<Params>();
  const { data, loading, error } = useQuery<
    CollectionType,
    CollectionVariables
  >(COLLECTION, {
    variables: {
      id: id,
    },
  });

  const [
    addToCloudStorage,
    { loading: isAddingToCloudStorage, error: errorAddingToCloudStorage },
  ] = useMutation<AddToCloudStorage, AddToCloudStorageVariables>(
    ADD_TO_CLOUD_STORAGE
  );
  const [
    addToSolana,
    { loading: isAddingToSolana, error: errorAddingToSolana },
  ] = useMutation<AddItemsToSolana, AddItemsToSolanaVariables>(
    ADD_ITEMS_TO_SOLANA
  );
  const [
    createCandyMachine,
    { loading: isCreatingCandyMachine, error: errorCreatingCandyMachine },
  ] = useMutation<CreateCandyMachine, CreateCandyMachineVariables>(
    CREATE_CANDY_MACHINE
  );
  const wallet = useAnchorWallet();
  const { connection } = useConnection();

  const collection = data?.collection;

  const mintFromCollection = async () => {
    if (wallet) {
      const mintTxId = await mintOneToken(
        wallet,
        collection?.candy_machine_address!,
        SOLANA_PUBLIC_KEY!
      );

      const status = await awaitTransactionSignatureConfirmation(
        mintTxId!,
        30000,
        connection,
        "singleGossip",
        false
      );

      console.log("status", status);
      if (status && !status?.err) {
        alert("Mint success");
      } else {
        alert("Mint failed");
      }
    }
  };

  return (
    <div className="App">
      {error != null ? (
        <>
          `Error loading collection {id}! ${error.message}`
        </>
      ) : loading ? (
        <>"Loading Collection..."</>
      ) : collection != null ? (
        <div>
          <h1>Collection {id}</h1>

          <h2>Id</h2>
          <p>{collection.id}</p>

          <h2>Metadata</h2>
          <p>{JSON.stringify(collection.metadata)}</p>

          <h2>Status</h2>
          <p>{collection.status}</p>

          <h2>Program Account</h2>
          <p>{collection.program_account_address}</p>

          <h2>Candy Machine Address</h2>
          <p>{collection.candy_machine_address}</p>

          <h2>Mint Start Date</h2>
          <p>{collection.mint_start_time}</p>

          <h2>Price in Lamports</h2>
          <p>{collection.price_lamports}</p>

          <h2>Items</h2>
          <ul>
            {collection?.items?.map((item) => {
              if (item) {
                return (
                  <li key={item.id}>{Item({ ...item }, addToCloudStorage)}</li>
                );
              }
            })}
          </ul>

          <h2>Actions</h2>
          <button
            onClick={() => addToSolana({ variables: { id: collection.id } })}
          >
            Add Items to Solana
          </button>
          <button
            onClick={() => {
              createCandyMachine({
                variables: {
                  id: collection.id,
                },
              });
            }}
          >
            Create Candy Machine
          </button>
          <br />
          {!wallet ? (
            <WalletModalProvider>
              <WalletMultiButton />
              <WalletDisconnectButton />
            </WalletModalProvider>
          ) : (
            <button onClick={mintFromCollection}>Mint</button>
          )}
        </div>
      ) : (
        <div>No Collection for {id}</div>
      )}

      <GenerateItems collectionId={id} />
    </div>
  );
}
