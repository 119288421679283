import * as anchor from "@project-serum/anchor";
import { MintLayout, Token } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  CANDY_MACHINE,
  CANDY_MACHINE_PROGRAM_V2_ID,
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  TOKEN_METADATA_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "./constants";
import { sendTransactionWithRetry } from "./connection";
import { AnchorWallet } from "@solana/wallet-adapter-react";

export interface CandyMachineState {
  id: string;
  program: anchor.Program;
  state: {
    itemsAvailable: number;
    itemsRedeemed: number;
    itemsRemaining: number;
    goLiveDate: Date;
    isSoldOut: boolean;
    isActive: boolean;
    treasury: string;
    tokenMint: string;
    config: any;
    price: number;
  };
}

export const getCandyMachineCreator = async (
  candyMachine: anchor.web3.PublicKey
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("candy_machine"), candyMachine.toBuffer()],
    CANDY_MACHINE_PROGRAM_V2_ID
  );
};

export const deriveCandyMachineV2ProgramAddress = async (
  candyMachineId: anchor.web3.PublicKey
): Promise<[PublicKey, number]> => {
  return await PublicKey.findProgramAddress(
    [Buffer.from(CANDY_MACHINE), candyMachineId.toBuffer()],
    CANDY_MACHINE_PROGRAM_V2_ID
  );
};

export const getCandyMachine = async (wallet: AnchorWallet) => {
  const connection = new anchor.web3.Connection(
    // @ts-ignore
    anchor.web3.clusterApiUrl(process.env.REACT_APP_SOLANA_ENV)
  );

  const walletWrapper = wallet;
  const provider = new anchor.Provider(connection, walletWrapper, {
    preflightCommitment: "recent",
  });

  const idl = await anchor.Program.fetchIdl(
    CANDY_MACHINE_PROGRAM_V2_ID,
    provider
  );
  const program = new anchor.Program(
    idl!,
    CANDY_MACHINE_PROGRAM_V2_ID,
    provider
  );
  return { connection, program };
};

export const getCandyMachineState = async (
  wallet: AnchorWallet,
  candyMachineAddr: string
): Promise<CandyMachineState> => {
  const { program } = await getCandyMachine(wallet);
  const state: any = await program.account.candyMachine.fetch(candyMachineAddr);

  const itemsAvailable = state.data.itemsAvailable.toNumber();
  const itemsRedeemed = state.itemsRedeemed.toNumber();
  const itemsRemaining = itemsAvailable - itemsRedeemed;

  let goLiveDate = state.data.goLiveDate.toNumber();
  goLiveDate = new Date(goLiveDate * 1000);

  console.log("getCandyMachineState", {
    itemsAvailable,
    itemsRedeemed,
    itemsRemaining,
    goLiveDate,
  });

  return {
    id: candyMachineAddr,
    program,
    state: {
      itemsAvailable,
      itemsRedeemed,
      itemsRemaining,
      isSoldOut: itemsRemaining === 0,
      isActive: state.data.goLiveDate.toNumber() < new Date().getTime() / 1000,
      goLiveDate: state.data.goLiveDate,
      treasury: state.wallet,
      tokenMint: state.tokenMint,
      config: state.config,
      price: state.data.price,
    },
  };
};

const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

const getTokenWallet = async (wallet: PublicKey, mint: PublicKey) => {
  return (
    await PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0];
};

const getMasterEdition = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from("edition"),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

const createAssociatedTokenAccountInstruction = (
  associatedTokenAddress: PublicKey,
  payer: PublicKey,
  walletAddress: PublicKey,
  splTokenMintAddress: PublicKey
) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    data: Buffer.from([]),
  });
};

export const mintOneToken = async (
  wallet: AnchorWallet, // the wallet that is paying for the mint
  candyMachineAddr: string, // the specific instance of the candy machine program
  treasuryKeyStr: string // the key that gets paid for the mint
): Promise<string | null> => {
  console.log("mintOneToken", {
    wallet,
    candyMachineAddr,
    treasuryKeyStr,
  });
  const { connection, program } = await getCandyMachine(wallet);
  const mint = Keypair.generate();

  console.log("Generating public keys from key strs");
  const payer = wallet.publicKey;
  const treasury = new PublicKey(treasuryKeyStr);

  console.log("Getting token wallet ready for minting");
  const token = await getTokenWallet(payer, mint.publicKey);

  console.log("Checking wallet metadata for new mint account");
  const metadata = await getMetadata(mint.publicKey);
  const masterEdition = await getMasterEdition(mint.publicKey);

  const candyMachineState = await getCandyMachineState(
    wallet,
    candyMachineAddr
  );

  const rent = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );
  const [candyMachineCreator, creatorBump] = await getCandyMachineCreator(
    new PublicKey(candyMachineAddr)
  );

  console.log("RPCing to mint NFT", {
    accounts: {
      candyMachine: new PublicKey(candyMachineAddr),
      candyMachineCreator,
      payer: payer,
      wallet: treasury,
      mint: mint.publicKey,
      metadata,
      masterEdition,
      mintAuthority: payer,
      updateAuthority: payer,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
    },
    signers: [mint],
  });
  const instructions = [
    anchor.web3.SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: mint.publicKey,
      space: MintLayout.span,
      lamports: rent,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitMintInstruction(
      TOKEN_PROGRAM_ID,
      mint.publicKey,
      0,
      payer,
      payer
    ),
    createAssociatedTokenAccountInstruction(
      token,
      payer,
      payer,
      mint.publicKey
    ),
    Token.createMintToInstruction(
      TOKEN_PROGRAM_ID,
      mint.publicKey,
      token,
      payer,
      [],
      1
    ),
    await candyMachineState.program.instruction.mintNft(creatorBump, {
      accounts: {
        candyMachine: new PublicKey(candyMachineAddr),
        candyMachineCreator,
        payer: payer,
        wallet: treasury,
        mint: mint.publicKey,
        metadata,
        masterEdition,
        mintAuthority: payer,
        updateAuthority: payer,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        recentBlockhashes: anchor.web3.SYSVAR_RECENT_BLOCKHASHES_PUBKEY,
        instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
      },
    }),
  ];

  try {
    return (
      await sendTransactionWithRetry(
        candyMachineState.program.provider.connection,
        candyMachineState.program.provider.wallet,
        instructions,
        [mint]
      )
    ).txid;
  } catch (e) {
    console.log(e);
  }
  return null;
};
