import React, { useState } from "react";
import { cx, css } from "@emotion/css";
import WalletButton from "./login/WalletButton";

export default function TopNav() {
  const [isActive, setIsActive] = useState<Boolean>(false);
  const toggleIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="Global">
        <div className="navbar-brand">
          <a href="#" className={cx(styles.navLink, "column")}>
            <i className="fas fa-home fa-2x"></i>
          </a>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="topNav"
            onClick={toggleIsActive}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          id="topNav"
          className={cx("navbar-menu", isActive ? "is-active" : null)}
        >
          <div className="navbar-start">
            <a href="#" className={cx(styles.navLink, "navbar-item")}>
              Events
            </a>

            <a href="#" className={cx(styles.navLink, "navbar-item")}>
              Tickets
            </a>

            <a href="#" className={cx(styles.navLink, "navbar-item")}>
              FAQ
            </a>

            <div className={cx(styles.navLink, "navbar-item")}>
              <WalletButton />
            </div>
          </div>
        </div>
        <div className="navbar-end"></div>
      </nav>
    </>
  );
}

const styles = {
  navLink: css`
    align-self: center;
  `,
};
