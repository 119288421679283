import React from "react";
import { cx, css } from "@emotion/css";
import { fonts } from "../css/examples";

export default function Landing() {
  return (
    <div>
      <p className={cx(styles.greenFont, styles.blueBackground)}>
        hello friend
      </p>

      <div className="columns is-gapless">
        <div className="column">No gap</div>
        <div className={cx(styles.greenFont, styles.blueBackground, "column")}>
          No gap
        </div>
        <div className={cx(fonts.redFont, "column")}>No gap</div>
        <div className="column">No gap</div>
      </div>
    </div>
  );
}

const styles = {
  greenFont: css`
    font-size: 20px;
    color: green;
  `,
  blueBackground: css`
    background: blue;
  `,
};
