import { gql } from "@apollo/client";

export const GET_QR_CODE = gql`
  query GetQRCode {
    getQRCode
  }
`;

export const RESOLVE_QR_CODE = gql`
  query ResolveQRCode($code: String!) {
    resolveQRCode(code: $code)
  }
`;
