import React from "react";
import { useMutation } from "@apollo/client";

import {
  GenerateItemsFromTemplate,
  GenerateItemsFromTemplateVariables,
} from "../../graphql/__generated__/GenerateItemsFromTemplate";
import { GENERATE_ITEMS_FROM_TEMPLATE } from "../../graphql/collections.graphql";

export default function GenerateItems(props: { collectionId: string }) {
  const [generate, { loading, error }] = useMutation<
    GenerateItemsFromTemplate,
    GenerateItemsFromTemplateVariables
  >(GENERATE_ITEMS_FROM_TEMPLATE);
  return (
    <div className="">
      <div>Generate items</div>
      <button
        onClick={async () => {
          await generate({
            variables: {
              id: props.collectionId,
            },
          });
        }}
      >
        Generate
      </button>
    </div>
  );
}
