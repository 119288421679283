import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CONVERSATION,
  MESSAGES,
  SEND_MESSAGE,
  SUBSCRIBE_TO_CONVERSATION,
} from "../../graphql/conversations.graphql";
import {
  Conversation as ConversationType,
  ConversationVariables,
} from "../../graphql/__generated__/Conversation";
import {
  Messages as MessagesType,
  MessagesVariables,
} from "../../graphql/__generated__/Messages";
import {
  SendMessage,
  SendMessageVariables,
} from "../../graphql/__generated__/SendMessage";
import Messages from "./Messages";
import { SubscribeToConversation } from "../../graphql/__generated__/SubscribeToConversation";

export default function Conversation(props: { id: string }) {
  const { data, loading, error } = useQuery<
    ConversationType,
    ConversationVariables
  >(CONVERSATION, {
    variables: {
      id: props.id,
    },
  });

  const {
    subscribeToMore,
    data: messages,
    loading: messagesLoading,
    error: messagesError,
  } = useQuery<MessagesType, MessagesVariables>(MESSAGES, {
    variables: {
      id: props.id,
      limit: 25,
      offset: 0,
    },
  });
  const [
    sendMessage,
    { loading: isSendingMessage, error: errorSendingMessage },
  ] = useMutation<SendMessage, SendMessageVariables>(SEND_MESSAGE);
  const [msg, setMsg] = useState<string>("");

  useEffect(() => {
    subscribeToMore<SubscribeToConversation>({
      document: SUBSCRIBE_TO_CONVERSATION,
      variables: { id: props.id },
      updateQuery: (prev, { subscriptionData }) => {
        const newMessage = subscriptionData.data.subscribeToConversation;
        if (!newMessage) return prev;
        return Object.assign({}, prev, {
          messages: [...prev.messages, newMessage],
        });
      },
    });
  }, []);

  return (
    <>
      {loading ? null : <h2>{data?.conversation?.event.name}</h2>}
      <ul>
        {messagesLoading || !messages ? null : <Messages data={messages} />}
      </ul>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (msg.length > 0) {
            sendMessage({
              variables: { body: msg, id: props.id },
            });
          }
          // @ts-ignore
          e.target.reset();
        }}
      >
        <input type="text" onChange={(e) => setMsg(e.target.value)} />
        <button className="button" type="submit">
          Send
        </button>
      </form>
    </>
  );
}
