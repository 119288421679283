import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useMutation } from "@apollo/client";
import { CREATE_EVENT } from "../../graphql/events.graphql";
import { CreateEvent } from "../../graphql/__generated__/CreateEvent";

function getTimeStringFromSeconds(seconds: number) {
  var date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  var timeString = date.toISOString().substr(11, 8);
  return timeString;
}

const VenueSelectionForm = (
  <>
    <div className="col-span-5">
      <label htmlFor="street-address" className="">
        Street address
      </label>
      <input
        type="text"
        name="street-address"
        id="street-address"
        autoComplete="street-address"
        className=""
      />
    </div>

    <div className="col-span-5 lg:col-span-2">
      <label htmlFor="city" className="">
        City
      </label>
      <input
        type="text"
        name="city"
        id="city"
        autoComplete="address-level2"
        className=""
      />
    </div>

    <div className="col-span-5 sm:col-span-2 lg:col-span-1">
      <label htmlFor="region" className="">
        State / Province
      </label>
      <input
        type="text"
        name="region"
        id="region"
        autoComplete="address-level1"
        className=""
      />
    </div>

    <div className="col-span-5 sm:col-span-3 lg:col-span-2">
      <label htmlFor="postal-code" className="">
        ZIP / Postal code
      </label>
      <input
        type="text"
        name="postal-code"
        id="postal-code"
        autoComplete="postal-code"
        className=""
      />
    </div>
  </>
);

const VirtualEventForm = (
  <>
    <div className="col-span-5 sm:col-span-4">
      <label htmlFor="url" className="">
        URL
      </label>
      <input
        type="text"
        name="url"
        id="url"
        placeholder="The URL where you'll be hosting your event"
        className=""
      />
    </div>
  </>
);

export default function CreateEventForm() {
  const [createEventWithVenue, { data, loading, error }] =
    useMutation<CreateEvent>(CREATE_EVENT);

  const defaultDate = new Date().getTime();

  const [eventType, setEventType] = useState<number>(0);
  const [name, setName] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [eventStartDate, setEventStartDate] = useState<number>(defaultDate);
  const [eventStartTime, setEventStartTime] = useState<number>(0);
  const [eventEndDate, setEventEndDate] = useState<number>(defaultDate);
  const [eventEndTime, setEventEndTime] = useState<number>(0);

  const [saleStartDate, setSaleStartDate] = useState<number>(defaultDate);
  const [saleStartTime, setSaleStartTime] = useState<number>(0);
  const [saleEndDate, setSaleEndDate] = useState<number>(defaultDate);
  const [saleEndTime, setSaleEndTime] = useState<number>(0);

  return (
    <>
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <h3 className="">Event Information</h3>
              <p className="">
                Edit details about your event and a preview will appear here
              </p>
            </div>
          </div>
          <div className="">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createEventWithVenue({
                  variables: {
                    name: name,
                    description: description,
                    url: url,
                    type: eventType,
                    event_start: new Date(eventStartDate + eventStartTime),
                    event_end: new Date(eventEndDate + eventEndTime),
                    sale_start: new Date(saleStartDate + saleStartTime),
                    sale_end: new Date(saleEndDate + saleEndTime),
                  },
                });
              }}
            >
              <h1> Basic Info </h1>
              <p>
                Name your event and tell event-goers why they should come. Add
                details that highlight what makes it unique
              </p>

              <div>
                <label>Event Title</label>
                <input
                  type="text"
                  placeholder="Be clear and descriptive"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Tell attendees about the event"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div>
                <label>Event url</label>
                <input
                  type="text"
                  placeholder="Event url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>

              <div>
                <h2> Location </h2>
                <div>
                  <label>Online event?</label>
                  <input
                    type="checkbox"
                    placeholder="online"
                    value={eventType === 1 ? "checked" : "unchecked"}
                    onChange={(e) =>
                      setEventType(e.target.value === "checked" ? 1 : 0)
                    }
                  />
                </div>
              </div>

              <div>
                <label>Event Start date</label>
                <input
                  type="date"
                  placeholder="start"
                  value={new Date(eventStartDate).toISOString().substr(0, 10)}
                  onChange={(e) => setEventStartDate(e.target.valueAsNumber)}
                />
                <input
                  type="time"
                  placeholder="start"
                  value={getTimeStringFromSeconds(eventStartTime)}
                  onChange={(e) =>
                    setEventStartTime(e.target.valueAsNumber / 1000)
                  }
                />
                <div>
                  <label>Event End date</label>
                  <input
                    type="date"
                    placeholder="end"
                    value={new Date(eventEndDate).toISOString().substr(0, 10)}
                    onChange={(e) => setEventEndDate(e.target.valueAsNumber)}
                  />
                  <input
                    type="time"
                    placeholder="end"
                    value={getTimeStringFromSeconds(eventEndTime)}
                    onChange={(e) =>
                      setEventEndTime(e.target.valueAsNumber / 1000)
                    }
                  />
                </div>
              </div>

              <div>
                <label>Sale Start date</label>
                <input
                  type="date"
                  placeholder="start"
                  value={new Date(saleStartDate).toISOString().substr(0, 10)}
                  onChange={(e) => setSaleStartDate(e.target.valueAsNumber)}
                />
                <input
                  type="time"
                  placeholder="start"
                  value={getTimeStringFromSeconds(saleStartTime)}
                  onChange={(e) => {
                    setSaleStartTime(e.target.valueAsNumber / 1000);
                  }}
                />
                <div>
                  <label>Sale End date</label>
                  <input
                    type="date"
                    placeholder="end"
                    value={new Date(eventEndDate).toISOString().substr(0, 10)}
                    onChange={(e) => setSaleEndDate(e.target.valueAsNumber)}
                  />
                  <input
                    type="time"
                    placeholder="end"
                    value={getTimeStringFromSeconds(saleEndTime)}
                    onChange={(e) =>
                      setSaleEndTime(e.target.valueAsNumber / 1000)
                    }
                  />
                </div>
              </div>

              {error != null ? (
                <>`Error Creating Event! ${error.message}`</>
              ) : null}
              {loading ? (
                <>"Creating Event..."</>
              ) : data?.createEvent != null ? (
                <div>
                  <h1>Event created!</h1>
                  <p>id: {data.createEvent.id}</p>
                  <p>name: {data.createEvent.name}</p>
                  <p>description: {data.createEvent.description}</p>
                  <p>max tickets: {data.createEvent.url}</p>
                  <p>online event?: {data.createEvent.type}</p>
                  <p>status: {data.createEvent.status}</p>
                  <p>eventstartdate: {data.createEvent.event_start}</p>
                  <p>eventenddate: {data.createEvent.event_end}</p>
                  <p>salestartdate: {data.createEvent.sale_start}</p>
                  <p>saleenddate: {data.createEvent.sale_end}</p>
                </div>
              ) : (
                <button type="submit">Create Event</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
