import React, { useEffect } from "react";
import qrCode from "qrcode";
import { useQuery } from "@apollo/client";
import { GET_QR_CODE } from "../../graphql/users.graphql";
import { GetQRCode } from "../../graphql/__generated__/GetQRCode";

export default function QRCode() {
  const { data, loading, error } = useQuery<GetQRCode>(GET_QR_CODE);

  const canvas = React.createRef<HTMLCanvasElement>();
  useEffect(() => {
    if (data) {
      qrCode.toCanvas(canvas.current, data.getQRCode, (error) => {
        if (error) console.log(error);
      });
    }
  }, [data]);

  return (
    <>
      <div>
        <canvas ref={canvas} />
      </div>
    </>
  );
}
