import { gql } from "@apollo/client";

export const ITEM = gql`
  query Item($id: String!) {
    item(id: $id) {
      id
      ipfs_metadata
      metadata
      s3_url
      status
    }
  }
`;

export const CREATE_ITEM = gql`
  mutation CreateItem(
    $name: String!
    $description: String!
    $collectionId: String!
  ) {
    createItem(
      name: $name
      description: $description
      collectionId: $collectionId
    ) {
      id
      ipfs_metadata
      metadata
      s3_url
      status
    }
  }
`;

export const ADD_TO_CLOUD_STORAGE = gql`
  mutation AddToCloudStorage($id: String!) {
    addToCloudStorage(id: $id) {
      id
      ipfs_metadata
      metadata
      s3_url
      status
    }
  }
`;
