import React from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  CurrencyDollarIcon,
  LinkIcon,
  LocationMarkerIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { Event_event as EventType } from "../../graphql/__generated__/Event";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function EventListItem(event: EventType) {
  return (
    <div className="">
      <div className="">
        <h2 className="">{event.name}</h2>
        <div className="">
          <div className="">
            <BriefcaseIcon className="" aria-hidden="true" />
            Full-time
          </div>
          <div className="">
            <LocationMarkerIcon className="" aria-hidden="true" />
            {event.venue?.name}
          </div>
          <div className="">
            <CurrencyDollarIcon className="" aria-hidden="true" />
            {event.url}
          </div>
          <div className="">
            <CalendarIcon className="" aria-hidden="true" />
            {event.sale_start}
          </div>
        </div>
      </div>
      <div className="">
        <span className="">
          <button type="button" className="">
            <PencilIcon className="" aria-hidden="true" />
            Edit
          </button>
        </span>

        <span className="">
          <button type="button" className="">
            <LinkIcon className="" aria-hidden="true" />
            View
          </button>
        </span>

        <span className="">
          <button type="button" className="">
            <CheckIcon className="" aria-hidden="true" />
            Publish
          </button>
        </span>
      </div>
    </div>
  );
}
